<template>
  <div>
    <SearchPanel
      flex
      show-btn
      @getList="getSearchList"
    >
      <div>
        <p>结算单号</p>
        <el-input
          v-model="settlementNo"
          size="mini"
          clearable
          placeholder="请输入结算单号"
        />
      </div>
      <div>
        <p>订单编号</p>
        <el-input
          v-model="orderNo"
          size="mini"
          clearable
          placeholder="请输入订单编号"
        />
      </div>
      <div>
        <p>提现ID</p>
        <el-input
          v-model="withdrawId"
          size="mini"
          placeholder="请输入提现ID"
        />
      </div>
      <div>
        <p>结算单创建起始时间</p>
        <el-date-picker
          v-model="startTime"
          type="datetime"
          clearable
          size="mini"
          placeholder="选择结算单创建起始时间"
        />
      </div>
      <div>
        <p>结算单创建结束时间</p>
        <el-date-picker
          v-model="endTime"
          type="datetime"
          clearable
          size="mini"
          placeholder="选择结算单创建结束时间"
        />
      </div>
      <div>
        <p>结算状态</p>
        <el-select
          v-model="settlementStatus"
          size="mini"
          clearable
        >
          <el-option
            v-for="(item,index) in settlementStatusArr"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div>
        <p>下单开始时间</p>
        <el-date-picker
          v-model="placeStartTime"
          type="datetime"
          size="mini"
          :clearable="false"
          placeholder="选择结下单开始时间"
        />
      </div>
      <div>
        <p>下单结束时间</p>
        <el-date-picker
          v-model="placeEndTime"
          type="datetime"
          size="mini"
          :clearable="false"
          placeholder="选择下单结束时间"
        />
      </div>
      <div>
        <p>店铺优惠券ID</p>
        <el-input
          v-model="couponId"
          size="mini"
          :clearable="false"
          placeholder="请输入优惠券ID"
        />
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="download('export')"
      >
        下载订单明细表
      </el-button>
      <el-button
        slot="button-right"
        size="mini"
        @click="download('goods')"
      >
        下载商品明细表按钮
      </el-button>
    </SearchPanel>
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8', 'color': '#333'}"
      element-loading-text="拼命加载中"
      border
      style="width: 100%"
    >
      <el-table-column
        prop="projectCodeName"
        label="平台"
      />
      <el-table-column
        prop="withdrawId"
        label="提现ID"
      />
      <el-table-column
        prop="settlementNo"
        label="结算单号"
      />
      <el-table-column
        prop="orderNo"
        label="订单编号"
      />
      <el-table-column
        prop="payment"
        label="买家实付"
      />
      <el-table-column
        prop="refundFee"
        label="退款金额"
      />
      <el-table-column
        prop="couponFee"
        label="平台补贴金额"
      />
      <el-table-column
        prop="commissionFee"
        label="佣金"
      />
      <el-table-column
        prop="handlingFee"
        label="手续费"
      />
      <el-table-column
        prop="settlementFee"
        label="结算金额"
      />
      <el-table-column
        prop="settlementStatusName"
        label="结算状态"
      />
      <el-table-column
        prop="createTime"
        label="结算单创建时间"
      >
        <template slot-scope="scope">
          {{ setTime(scope.row.createTime) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pagination.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
import dayjs from 'dayjs';

import { downloadFile } from '@/utils/download';

export default {
  name: 'settlement-list-component',
  components: {

  },
  data() {
    return {
      tableData: [],
      loading: false,
      settlementNo: null,
      orderNo: null,
      startTime: '',
      endTime: '',
      // 分页
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
      couponId: '',
      withdrawId: null, // 提现id
      settlementStatus: null,
      settlementStatusArr: [
        {
          label: '待结算',
          value: 1,
        },
        {
          label: '可提现',
          value: 2,
        },
        {
          label: '待打款',
          value: 3,
        },
        {
          label: '已提现',
          value: 4,
        },
      ], // 状态
      placeStartTime: dayjs().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'),
      placeEndTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 点击搜索
    getSearchList() {
      this.pagination.currentPage = 1;
      this.getList();
    },
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: this.$api.settlement.list,
        data: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          settlementNo: this.settlementNo,
          orderNo: this.orderNo,
          startSettlementCreateTime: this.startTime ? dayjs(dayjs(this.startTime).valueOf()).toJSON() : null,
          endSettlementCreateTime: this.endTime ? dayjs(dayjs(this.endTime).valueOf()).toJSON() : null,
          startOrderCreateTime: this.placeStartTime ? dayjs(dayjs(this.placeStartTime).valueOf()).toJSON() : null,
          endOrderCreateTime: this.placeEndTime ? dayjs(dayjs(this.placeEndTime).valueOf()).toJSON() : null,
          withdrawId: this.withdrawId,
          settlementStatus: this.settlementStatus,
          couponId: this.couponId,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          this.pagination.count = Number(res.data.total);
        } else {
          this.$message(res.error_msg);
        }
        this.loading = false;
      });
    },
    download(type) {
      const endOrderCreateTime = this.placeEndTime ? dayjs(dayjs(this.placeEndTime).valueOf()).toJSON() : null;
      const startOrderCreateTime = this.placeStartTime ? dayjs(dayjs(this.placeStartTime).valueOf()).toJSON() : null;

      console.log(startOrderCreateTime, endOrderCreateTime);

      if (!startOrderCreateTime || !endOrderCreateTime) {
        this.$message.error('请选择下单时间');
        return;
      }

      this.$message.success('正在导出数据，请稍后前往浏览器下载列表查看下载文件');
      let api = this.$api.settlement.download;
      let name = '订单明细数据.xls';
      if (type === 'goods') {
        api = this.$api.settlement.downloadGoods;
        name = '商品明细数据.xls';
      }
      this.$axios({
        method: 'post',
        url: api,
        data: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          settlementNo: this.settlementNo,
          orderNo: this.orderNo,
          startSettlementCreateTime: this.startTime ? dayjs(dayjs(this.startTime).valueOf()).toJSON() : null,
          endSettlementCreateTime: this.endTime ? dayjs(dayjs(this.endTime).valueOf()).toJSON() : null,
          startOrderCreateTime: this.placeStartTime ? dayjs(dayjs(this.placeStartTime).valueOf()).toJSON() : null,
          endOrderCreateTime: this.placeEndTime ? dayjs(dayjs(this.placeEndTime).valueOf()).toJSON() : null,
          withdrawId: this.withdrawId,
          settlementStatus: this.settlementStatus,
          couponId: this.couponId,
        },
        responseType: 'blob',
      }).then((res) => {
        downloadFile(res, name);
      });
    },
    setTime(time) {
      return time ? dayjs(time).format('YYYY/MM/DD HH:mm:ss') : '';
    },
  },
};
</script>
