// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.saw-notice-box .title {\n  margin-bottom: 20px;\n  font-size: 20px;\n  text-align: center;\n}\n.saw-notice-box .content {\n  line-height: 24px;\n  margin-bottom: 20px;\n}\n.saw-notice-box .btn-box {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n.saw-notice-box .check-box {\n  height: 32px;\n}\n.saw-notice-box .el-button {\n  width: 150px;\n  margin-top: 20px;\n}\n.saw-notice-box .check-err {\n  padding-left: 24px;\n  font-size: 12px;\n  color: red;\n}\n.saw-notice-box .el-checkbox__inner {\n  top: -1.6px;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
