<template>
  <div class="type-chart">
    <div id="type-price-chart" />
  </div>
</template>
<script>
import echarts from 'echarts'
export default {
  name: 'TypeChart',
  props: {
    typePriceData: {
      type: Object,
      default: () => {
        return {
          index: [],
          num: []
        }
      }
    }
  },
  data() {
    return {
      typeEchart: '',
      typeData: {}
    }
  },
  watch: {
    typePriceData() {
      this.echartsInit()
    }

  },
  mounted() {
    this.typeEchart = echarts.init(document.getElementById('type-price-chart'))
    this.echartsInit()
  },
  methods: {
    echartsInit() {
      this.typeData = {
        title: {
          text: '订单金额分布',
          subtext: '订单数',
          left: 'left'
        },
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.typePriceData.index,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '订单数',
            type: 'bar',
            barWidth: '30%',
            data: this.typePriceData.num
          }
        ]
      }
      if (this.typeData && typeof this.typeData === 'object') {
        this.typeEchart.setOption(this.typeData, true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#type-price-chart{
  height:420px;
  overflow: hidden;
  margin: 0 auto;
}
</style>
