<template>
  <div>
    <el-table
      v-loading="loading"
      :data="tableData"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8', 'color': '#333'}"
      element-loading-text="拼命加载中"
      border
      style="width: 100%"
    >
      <!-- <el-table-column
        prop="id"
        width="80"
        label="序号"
      >
      </el-table-column> -->
      <el-table-column
        prop="withdrawId"
        label="提现ID"
      />
      <el-table-column
        prop="projectCodeName"
        label="平台"
      />
      <el-table-column
        prop="withdrawAmount"
        label="金额"
      />
      <el-table-column
        prop="withdrawStatusName"
        label="状态"
      />
      <el-table-column
        prop="rejectReasonName"
        label="备注"
      />
      <el-table-column
        prop="createTime"
        label="操作时间"
      >
        <template slot-scope="scope">
          {{ setTime(scope.row.createTime) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pagination.nowPageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
import dayjs from 'dayjs';
// 提现记录
export default {
  name: 'withdraw-list-component',
  data() {
    return {
      tableData: [],
      loading: false,
      // 分页
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val;
      this.pagination.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$axios({
        method: 'post',
        url: this.$api.settlement.withdrawList,
        data: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          storeName: this.storeName,
          startTime: this.createStartTime ? this.createStartTime : null,
          endTime: this.createEndTime ? this.createEndTime : null,
        },
      }).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records;
          console.log(this.tableData, '====');
          this.pagination.count = Number(res.data.total);
        } else {
          this.$message(res.error_msg);
        }
        this.loading = false;
      });
    },
    setTime(time) {
      return time ? dayjs(time).format('YYYY-MM-DD HH:mm:ss') : '';
    },
  },
};
</script>
