var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "forms-box" }, [
        _c(
          "div",
          { staticClass: "forms-items" },
          [
            _c("p", [_vm._v("订单编号：")]),
            _c("el-input", {
              attrs: {
                size: "mini",
                placeholder: "请输入订单编号",
                clearable: "",
              },
              model: {
                value: _setup.forms.orderNo,
                callback: function ($$v) {
                  _vm.$set(_setup.forms, "orderNo", $$v)
                },
                expression: "forms.orderNo",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "forms-items" },
          [
            _c("p", [_vm._v("工会：")]),
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  filterable: "",
                  remote: "",
                  size: "mini",
                  "reserve-keyword": "",
                  placeholder: "请输入关键词搜索工会",
                  "remote-method": _setup.unionRemoteMethod,
                  loading: _setup.unionLoading,
                },
                on: {
                  change: function ($event) {
                    return _setup.changeUnion(_vm.item)
                  },
                },
                model: {
                  value: _setup.forms.userType,
                  callback: function ($$v) {
                    _vm.$set(_setup.forms, "userType", $$v)
                  },
                  expression: "forms.userType",
                },
              },
              _vm._l(_setup.unionOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "forms-items" },
          [
            _c("p", [_vm._v("福利券：")]),
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  filterable: "",
                  remote: "",
                  size: "mini",
                  "reserve-keyword": "",
                  placeholder: "请输入关键词搜索福利券",
                  "remote-method": _setup.welfareRemoteMethod,
                  loading: _setup.welfareLoading,
                },
                model: {
                  value: _setup.forms.relationId,
                  callback: function ($$v) {
                    _vm.$set(_setup.forms, "relationId", $$v)
                  },
                  expression: "forms.relationId",
                },
              },
              _vm._l(_setup.welfareOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "forms-items" },
          [
            _c("p", [_vm._v("状态：")]),
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  remote: "",
                  is_link: "",
                  size: "mini",
                  "reserve-keyword": "",
                },
                model: {
                  value: _setup.forms.settlementStatus,
                  callback: function ($$v) {
                    _vm.$set(_setup.forms, "settlementStatus", $$v)
                  },
                  expression: "forms.settlementStatus",
                },
              },
              _vm._l(_setup.typeOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "forms-items" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "mini",
                  loading: _setup.searchLoad,
                },
                on: {
                  click: function ($event) {
                    return _setup.handleSearch("searchLoad")
                  },
                },
              },
              [_vm._v(" 搜索 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { loading: _setup.resetLoad, size: "mini" },
                on: {
                  click: function ($event) {
                    return _setup.handleReset("resetLoad")
                  },
                },
              },
              [_vm._v(" 重置 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "mini", loading: _setup.downLoading },
                on: { click: _setup.download },
              },
              [_vm._v(" 导出 ")]
            ),
          ],
          1
        ),
      ]),
      _setup.searchSettlementStatus
        ? _c(
            "el-alert",
            {
              staticClass: "union-tip",
              attrs: { type: "error", closable: false },
            },
            [
              _c("template", { slot: "title" }, [
                _c("i", { staticClass: "el-icon-warning" }),
                _vm._v(
                  " 待结算技术服务费总计：" +
                    _vm._s(_setup.settledAmount) +
                    " 元 "
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _setup.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _setup.tableData,
            border: "",
            "header-cell-style": {
              background: "#D9DDE1",
              color: "#0D0202",
              "font-weight": 400,
            },
            "element-loading-text": "拼命加载中",
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "orderNo", label: "订单编号" },
          }),
          _c("el-table-column", { attrs: { prop: "userId", label: "用户ID" } }),
          _c("el-table-column", {
            attrs: { prop: "userTypeName", label: "工会" },
          }),
          _c("el-table-column", {
            attrs: { prop: "relationShowName", label: "福利券" },
          }),
          _c("el-table-column", {
            attrs: { prop: "membershipCardFee", label: "工会福利支付" },
          }),
          _c("el-table-column", {
            attrs: { prop: "payment", label: "买家实付" },
          }),
          _c("el-table-column", {
            attrs: { prop: "couponFee", label: "平台补贴金额" },
          }),
          _c("el-table-column", {
            attrs: { prop: "fullMinusPlatFee", label: "跨店满减平台承担金额" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "refundMembershipCardFee",
              label: "退款总额（工会福利|其他）",
              width: "210",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            row?.totalRefundFee +
                              " ( " +
                              row?.refundMembershipCardFee +
                              " | " +
                              row?.otherRefundFee +
                              " )"
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "serviceFee" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v("技术服务费金额")]),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content:
                            "（工会福利支付+买家实付+平台补贴金额+跨店满减平台承担优惠+积分抵扣金额-退款金额）*服务费率",
                          placement: "bottom",
                        },
                      },
                      [_c("i", { staticClass: "el-icon-question" })]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "佣金", prop: "commissionFee" },
          }),
          _c("el-table-column", {
            attrs: { prop: "handlingFee", label: "手续费" },
          }),
          _c("el-table-column", {
            attrs: { prop: "settlementStatusName", label: "状态" },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _setup.pagination.page,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _setup.pagination.page_count,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _setup.pagination.item_total,
        },
        on: {
          "size-change": _setup.handleSizeChange,
          "current-change": _setup.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }