<template>
  <div class="jdPrice-component">
    <div class="forms-box">
      <div class="forms-items">
        <p>结算周期</p>
        <el-date-picker
          v-model="forms.settlementInterval"
          size="mini"
          type="month"
          value-format="yyyy-MM"
          placeholder="选择月份"
        />
      </div>
      <div class="forms-items">
        <p>订单编号</p>
        <el-input
          v-model="forms.orderNo"
          size="mini"
        />
      </div>
      <div class="forms-items">
        <p>快递单号</p>
        <el-input
          v-model="forms.logisticNo"
          size="mini"
        />
      </div>
      <div class="forms-items">
        <p>发货时间</p>
        <el-date-picker
          v-model="forms.date"
          v-bind="statics.dataConfig"
        />
      </div>
      <div class="forms-items">
        <el-button
          type="primary"
          size="mini"
          :loading="searchLoad"
          @click="handleSearch('searchLoad')"
        >
          搜索
        </el-button>
        <el-button
          :loading="resetLoad"
          size="mini"
          @click="handleReset('resetLoad')"
        >
          重置
        </el-button>
        <el-button
          size="mini"
          :loading="downLoading"
          @click="download"
        >
          导出
        </el-button>
      </div>
    </div>

    <el-table
      v-loading="tableload"
      :data="list"
      border
      size="mini"
    >
      <el-table-column
        v-for="(item, index) in statics.header"
        :key="index"
        align="center"
        :label="item.label"
      >
        <template slot-scope="{row}">
          <el-tag
            v-if="item.prop === 'states'"
            size="small"
            :type="statics.statesType[row.jdSettlementStatus]"
          >
            {{ row[item.prop] }}
          </el-tag>
          <span v-else>{{ row[item.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="usePagAttrs.total"
      v-bind="usePagAttrs"
      v-on="pagEvents"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import {
  dataConfig, clone, lazy, tableAttrs,
} from '@/utils/common';
import { downloadFile } from '@/utils/download';

const header = {
  settlementInterval: '结算周期',
  withdrawId: '提现ID',
  projectCodeName: '平台',
  orderNo: '订单编号',
  originalAmount: '原始金额',
  logisticNo: '快递单号',
  settlementFee: '运费金额',
  states: '结算状态',
  orderTimes: '发货时间',
};
const statics = {
  dataConfig: dataConfig({ 'value-format': '' }),
  forms: {
    settlementInterval: '', orderNo: '', logisticNo: '', date: [],
  },
  header: Object.keys(header).map((key) => ({ prop: key, label: header[key] })),
  statesType: { 1: 'danger', 2: '', 3: 'success' },
  states: { 1: '未结算', 2: '结算中', 3: '已结算' },
};
export default {
  data() {
    this.statics = statics;
    return {
      ...tableAttrs(this),
      forms: clone(statics.forms),
      searchLoad: false,
      resetLoad: false,
      tableload: false,
      downLoading: false,
    };
  },
  computed: {
    supplierId() {
      return JSON.parse(localStorage.getItem('userInfo')).suppliersId; // 商家id
    },
    sendData() {
      return {
        ...this.forms,
        currentPage: this.usePagAttrs['current-page'],
        pageSize: this.usePagAttrs['page-size'],
        startOrderTime: this.forms.date ? this.forms.date[0] : '',
        endOrderTime: this.forms.date ? this.forms.date[1] : '',
      };
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleSearch(laod) {
      this[laod] = true;
      this.tableload = true;
      this.usePagAttrs['current-page'] = 1;
      lazy.call(this, () => {
        this.getData(laod);
      });
    },
    handleReset() {
      this.forms = clone(statics.forms);
      this.handleSearch('resetLoad');
    },
    async getData(load) {
      const res = await this.$axios.post(this.$api.order.jdList, this.sendData);
      this.tableload = false;
      this[load] = false;

      if (res.code === 0) {
        this.list = res.data.records;
        this.usePagAttrs.total = +res.data.total;

        this.list.forEach((item) => {
          item.states = statics.states[item.jdSettlementStatus];
          item.orderTimes = dayjs(item.orderTime).format('YYYY-MM-DD HH:mm:ss');
        });
      }
    },
    async download() {
      this.$message.success('正在导出数据，请稍后前往浏览器下载列表查看下载文件');
      const send = {
        supplierId: this.supplierId, // 商家id
        ...this.sendData,
      };
      this.downLoading = true;
      const res = await this.$axios.post(this.$api.order.jdDownLoad, send, { responseType: 'blob' });
      this.downLoading = false;
      downloadFile(res, '京东运费明细.xls');
    },
  },
};
</script>
