<template>
  <div>
    <div class="forms-box">
      <div class="forms-items">
        <p>订单编号：</p>
        <el-input
          v-model="forms.orderNo"
          size="mini"
          placeholder="请输入订单编号"
          clearable
        />
      </div>
      <div class="forms-items">
        <p>工会：</p>
        <el-select
          v-model="forms.userType"
          filterable
          remote
          size="mini"
          reserve-keyword
          placeholder="请输入关键词搜索工会"
          :remote-method="unionRemoteMethod"
          :loading="unionLoading"
          style="width: 100%"
          @change="changeUnion(item)"
        >
          <el-option
            v-for="item in unionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="forms-items">
        <p>福利券：</p>
        <el-select
          v-model="forms.relationId"
          filterable
          remote
          size="mini"
          reserve-keyword
          placeholder="请输入关键词搜索福利券"
          :remote-method="welfareRemoteMethod"
          :loading="welfareLoading"
          style="width: 100%"
        >
          <el-option
            v-for="item in welfareOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="forms-items">
        <p>状态：</p>
        <el-select
          v-model="forms.settlementStatus"
          remote
          is_link
          size="mini"
          reserve-keyword
          style="width: 100%"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="forms-items">
        <el-button
          type="primary"
          size="mini"
          :loading="searchLoad"
          @click="handleSearch('searchLoad')"
        >
          搜索
        </el-button>
        <el-button
          :loading="resetLoad"
          size="mini"
          @click="handleReset('resetLoad')"
        >
          重置
        </el-button>
        <el-button
          size="mini"
          :loading="downLoading"
          @click="download"
        >
          导出
        </el-button>
      </div>
    </div>
    <el-alert
      v-if="searchSettlementStatus"
      class="union-tip"
      type="error"
      :closable="false"
    >
      <template slot="title">
        <i class="el-icon-warning"></i>
        待结算技术服务费总计：{{ settledAmount }} 元
      </template>
    </el-alert>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      :header-cell-style="{
        background: '#D9DDE1',
        color: '#0D0202',
        'font-weight': 400
      }"
      element-loading-text="拼命加载中"
      style="width: 100%"
    >
      <el-table-column
        prop="orderNo"
        label="订单编号"
      />
      <el-table-column
        prop="userId"
        label="用户ID"
      />
      <el-table-column
        prop="userTypeName"
        label="工会"
      />
      <el-table-column
        prop="relationShowName"
        label="福利券"
      />
      <el-table-column
        prop="membershipCardFee"
        label="工会福利支付"
      />
      <el-table-column
        prop="payment"
        label="买家实付"
      />
      <el-table-column
        prop="couponFee"
        label="平台补贴金额"
      />
      <el-table-column
        prop="fullMinusPlatFee"
        label="跨店满减平台承担金额"
      />
      <el-table-column
        prop="refundMembershipCardFee"
        label="退款总额（工会福利|其他）"
        width="210"
      >
        <template #default="{row}">
          <div>
            {{
              row?.totalRefundFee+' ( '+row?.refundMembershipCardFee+' | '+row?.otherRefundFee+' )'
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="serviceFee">
        <template
          slot="header"
          slot-scope="scope"
        >
          <span>技术服务费金额</span>
          <el-tooltip
            class="item"
            effect="dark"
            content="（工会福利支付+买家实付+平台补贴金额+跨店满减平台承担优惠+积分抵扣金额-退款金额）*服务费率"
            placement="bottom"
          >
            <i class="el-icon-question"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label="佣金"
        prop="commissionFee"
      />
      <el-table-column
        prop="handlingFee"
        label="手续费"
      />
      <el-table-column
        prop="settlementStatusName"
        label="状态"
      />
    </el-table>
    <el-pagination
      :current-page="pagination.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pagination.page_count"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.item_total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script setup>
import dayjs from 'dayjs';
import {
  ref, reactive, getCurrentInstance, onMounted,
} from 'vue';
import { downloadFile } from '@/utils/download';

const app = getCurrentInstance().proxy; // 获取全局 Vue 实例
const loading = ref(false);
const unionLoading = ref(false); // 工会搜索相关
const unionOptions = ref([]);
const welfareLoading = ref(false); // 工会搜索相关
const welfareOptions = ref([]);
const tableData = ref([]);
const pagination = ref({
  page: 1,
  page_total: 1,
  item_total: 0,
  page_count: 10,
});
const typeOptions = ref([
  {
    value: '',
    label: '全部',
  },
  {
    value: 1,
    label: '待结算',
  },
  {
    value: 2,
    label: '已结算',
  },
]);
const settledAmount = ref(''); // 工会结算单待结算金额
// 定义可重用函数和初始数据
const originData = () => ({
  orderNo: '',
  userType: '',
  relationId: '',
  settlementStatus: '',
});
const searchSettlementStatus = ref(true);
// 赋值
const forms = reactive(originData());
const searchLoad = ref(false);
const resetLoad = ref(false);
const downLoading = ref(false);
onMounted(() => {
  getList(); // 获取工会结算单列表
  getUnionList(); // 获取工会列表
  getWelfareList(); // 获取工会下福利券列表
});

const getSettled = () => {
  app.$axios
    .post(app.$api.settlement.trade_settlement_union_settled, {
      ...forms,
    })
    .then((res) => {
      if (res.code !== 0) {
        return;
      }
      settledAmount.value = res.data;
    });
};

// 搜索工会
const unionRemoteMethod = (query) => {
  getUnionList(query);
};
// 获取工会列表数据
const getUnionList = (name) => {
  unionLoading.value = true;
  app.$axios
    .post(app.$api.settlement.union_list, {
      currentPage: 1,
      pageSize: 10,
      userTypeName: name,
    })
    .then((res) => {
      if (res.code !== 0) {
        return;
      }
      unionOptions.value = res.data.records.map((item) => {
        return {
          value: item.userType,
          label: item.userTypeName,
        };
      });
      unionLoading.value = false;
    });
};
// 选择工会
const changeUnion = () => {
  forms.relationId = '';
  getWelfareList();
};
// 搜索福利券
const welfareRemoteMethod = (query) => {
  getWelfareList(query);
};
// 获取福利券列表数据
const getWelfareList = (name) => {
  welfareLoading.value = true;
  app.$axios
    .post(app.$api.settlement.union_relation_list, {
      currentPage: 1,
      pageSize: 10,
      userType: forms.userType || '',
      relationShowName: name,
    })
    .then((res) => {
      if (res.code !== 0) {
        return;
      }
      welfareOptions.value = res.data.records.map((item) => {
        return {
          ...item,
          value: item.relationId,
          label: item.relationShowName,
        };
      });
      welfareLoading.value = false;
    });
};
const handleSizeChange = (val) => {
  pagination.value.page_count = val;
  pagination.value.page = 1;
  getList();
};
const handleCurrentChange = (val) => {
  pagination.value.page = val;
  getList();
};
const getList = (val) => {
  getSettled(); // 工会结算单待结算金额
  app.$axios
    .post(app.$api.settlement.trade_settlement_union_list, {
      ...forms,
      currentPage: pagination.value.page,
      pageSize: pagination.value.page_count,
    })
    .then((res) => {
      if (res.code === 0) {
        const {
          size, total, current, pages,
        } = res.data;
        pagination.value = {
          page: Number(current),
          page_count: Number(size),
          page_total: Number(pages),
          item_total: Number(total),
        };
        tableData.value = res.data.records;
        searchLoad.value = false;
      }
      if (forms.settlementStatus === 2) {
        searchSettlementStatus.value = false;
      } else {
        searchSettlementStatus.value = true;
      }
    });
};
const handleSearch = () => {
  searchLoad.value = true;
  pagination.value.page = 1;
  getList();
};
const handleReset = () => {
  resetLoad.value = true;
  pagination.value.page = 1;
  Object.assign(forms, originData());
  resetLoad.value = false;
  getUnionList(); // 获取工会列表
  getWelfareList();
};
const download = async () => {
  app.$message.success('正在导出数据，请稍后前往浏览器下载列表查看下载文件');
  const send = {
    ...forms,
    currentPage: pagination.value.page,
    pageSize: pagination.value.page_count,
  };
  downLoading.value = true;
  const res = await app.$axios.post(app.$api.settlement.trade_settlement_union_download, send, {
    responseType: 'blob',
  });
  downLoading.value = false;
  downloadFile(res, `工会福利类订单_${setTime(new Date())}.xls`);
};
const setTime = (time) => {
  return dayjs(+time).format('YYYY年MM月DD日 HH:mm:ss');
};

</script>
<style scoped lang="scss">
.union-tip {
  background: #e8f4ff;
  // border: 1px solid #a3d3ff;
  padding: 15px 0;
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
  .el-icon-warning {
    color: #409eff;
    font-size: 18px;
    padding: 0 5px;
    margin-right: 5px;
  }
}
.forms-box {
  margin-bottom: 0;
}
</style>
