<template>
  <div>
    <el-alert
      style="margin-bottom: 10px"
      title="注：充值金额不计入可提现金额"
      type="error"
      :closable="false">
    </el-alert>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
      element-loading-text="拼命加载中"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        label="充值ID"
      />
      <el-table-column
        prop="userId"
        label="用户ID"
      />
      <el-table-column
        prop="userName"
        label="用户名称"
      />
      <el-table-column
        prop="changeAmount"
        label="金额"
      />
      <el-table-column
        prop="businessTypeName"
        label="类型"
      />
      <el-table-column
        prop="memo"
        label="备注"
      />
      <el-table-column
        label="时间"
        width="210"
      >
        <template slot-scope="scope">
          {{ setTime(scope.row.createTime) }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pagination.page_count"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.item_total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  name: 'trade-info',
  props: {
    userId: String,
  },
  data() {
    return {
      loading: false,
      tableData: [], // 列表信息
      pagination: {
        page: 1,
        page_total: 1,
        item_total: 0,
        page_count: 10,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.page_count = val;
      this.pagination.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.getList();
    },
    getList() {
      this.$axios.post(this.$api.settlement.membership_settlement, {
        currentPage: this.pagination.page,
        pageSize: this.pagination.page_count,

      }).then((res) => {
        if (res.code === 0) {
          const {
            size, total, current, pages,
          } = res.data;
          this.pagination = {
            page: Number(current),
            page_count: Number(size),
            page_total: Number(pages),
            item_total: Number(total),
          };
          this.tableData = res.data.records;
        }
      });
    },
    setTime(time) {
      return dayjs(+time).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>
